@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "./utility";
@import "node_modules/css-star-rating/scss/star-rating";

@font-face {
  font-family: lookeFont;
  src: url("/fonts/Futura_ICG.ttf");
}

@font-face {
  font-family: lookeFont2;
  src: url("/fonts/FuturaLight.ttf");
}

@font-face {
  font-family: lookeFont3;
  src: url("/fonts/Futura-T-OT-Book_19064.ttf");
}

@font-face {
  font-family: lookeFont4;
  src: url("/fonts/ufonts.com_futura-bold-bt.ttf");
}

[v-cloak] {
  display: none;
}

body {
  font-family: lookeFont;
}

a:hover {
  color: #C6292E;
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>.active>span:focus {
  background-color: #C6292E;
  border-color: #C6292E;
}

.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus {
  color: #C6292E;
}

.pagination>li>a,
.pagination>li>span {
  color: #333;
}

#slider {
  padding-top: 114px;
}

#slider.no-nav-banner {
  padding-top: 69px;
}

//@media(max-width: 768px) {
//  #slider.no-nav-banner {
//    padding-top: 0px;
//  }
//}

#register,
#reseller,
#order,
#contact,
#faq,
#page-wrapper {
  padding-top: 114px;
  margin-bottom: 50px;
}

#content {
  padding: 30px 0;
}

footer {
  margin: 50px 0;
}

.navbar-default {
  background-color: #fff;
}

.navbar-default,
.navbar-inverse {
  transition: background-color .5s ease, border-color .5s ease;
}

.navbar-default .navbar-nav>li>a {
  color: #333;
}

.navbar-brand {
  line-height: 38px;
  padding-top: 10px;
}

.navbar-brand img {
  width: 110px;
}

@media (max-width: 768px) {
  .navbar-brand img {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .navbar-brand-reseller {
    margin-left: -45px;
  }
}


.navbar-nav>li>a {
  line-height: 48px;
  font-size: 13px;
}

.nav>li>a {
  padding: 10px 20px;
}

@media (min-width: 992px) {
  .main-navbar-nav {
    margin-left: 100px;
  }

  .navbar-nav-right {
    margin-top: 4px;
  }
}

.navbar-nav-right {
  float: right;
  padding-left: 0;
  padding-right: 8px;
  list-style: none;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .navbar-nav-right {
    margin-top: 4px;
  }

  .nav>li {
    border-top: 1px solid #ddd;
  }

  .navbar-nav {
    margin: -1.5px -15px;
  }
}

.navbar-nav-right>li {
  float: left;
  padding: 0 5px;
}

.navbar-nav-right>li>a {
  color: #9d9d9d;
  padding: 15px 10px 5px;
}

.main-navbar-nav {
  text-align: center;
}

.navbar-right>li>a {
  padding: 0;
}

.navbar-right>li {
  top: 20px;
  padding: 0 8px;
}

.language {
  top: 0 !important;
}

.language a {
  padding: 10px 10px !important;
}

.navbar-toggle {
  float: left;
  margin-top: 10px;
  margin-right: 0;
}

.navbar-inverse .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-inverse .navbar-toggle {
  border-color: transparent;
}

.navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
}

.navbar-toggle .icon-bar {
  width: 30px;
  height: 3px;
  margin: 8px 0;
}

.navbar-fixed-top {
  z-index: 1041;
}

.nav-banner {
  background-color: #171717;
  top: 0;
  position: fixed;
  display: block;
  width: 100%;
  min-height: 45px;
  z-index: 1041;
}

.nav-banner a {
  color: #ae0000;
}

.nav-banner a:hover {
  color: #fff;
}

//@media (max-width: 768px) {
//  .nav-banner, .navbar-fixed-top, #detail-float {
//    position: relative !important;
//  }
//
//  #detail-float {
//    top: -50px !important;
//  }
//
//  .navbar.has-nav-banner {
//    top: 0 !important;
//  }
//
//  #register, #reseller, #order, #contact, #faq, #page-wrapper, #slider {
//    padding-top: 0;
//  }
//
//  .navbar {
//    margin-bottom: 0;
//  }
//}

.nav-banner>.content {
  text-align: center;
  font-size: 2vh;
  color: #fff;
  padding-top: 12px;

  div {
    display: inline;
  }
}

.nav-banner>.content i {
  margin-left: 5px;
}

.navbar.has-nav-banner {
  top: 45px;
}

#footer {
  background-color: #151515;
  padding: 50px 0;
}

#footer a,
#footer p {
  color: #ffffff;
}

#footer a:hover {
  color: #ae0000;
  text-decoration: none;
}

#footer .row {
  padding: 10px 0;
}

#footer .logo {
  width: 20%;
}

.footer-menu {
  text-align: center;
}

#footer .media {
  list-style: none;
  float: left;
}

#footer .media li {
  float: left;
  padding: 0 5px;
}

#footer .media img {
  width: 20px;
  margin: 5px 13px;
}

#footer .list {
  list-style-type: none;
}

#footer .list li {
  padding-bottom: 10px;
}

#footer nav ul li {
  list-style: none;
  padding: 10px;
}

#footer .nav-bottom ul {
  padding-left: 20px;
  padding-right: 20px;
}

#footer label {
  color: #ffffff;
  margin-bottom: 20px;
}



@media (max-width: 991px) {
  #footer {
    padding: 20px 0px;
  }

  #footer .logo {
    width: 15%;
  }

  #footer .footer-social {
    padding-top: 20px;
  }

  #footer .media {
    padding-left: 0;
    margin: 10px 18%;
  }

  #footer .newsletter-wrapper {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-menu {
    padding: 0;
  }

}

@media (max-width: 768px) {
  .footer-menu>div {
    border-bottom: 1px solid #666666;
    padding: 15px 0;
  }

}

@media (max-width: 1200px) {
  #footer .media li {
    padding: 0 1px;
  }
}

.form-control,
.btn {
  border-radius: 0;
}

.btn-default {
  background-color: #cccccc;
}

.btn-default:hover,
.btn-default:focus {
  background-color: #9d9d9d;
}

.btn-dark {
  background-color: #2e3436;
  color: #ddd;
}

.btn-dark:hover,
.btn-dark:focus {
  background-color: #C6292E;
  color: #ddd;
}

.btn-red {
  background-color: #C6292E;
  color: #ddd;
}

.btn-red:hover,
.btn-red:focus {
  background-color: #474D4F;
  color: #ddd;
}

.btn-white {
  background-color: #fff;
  color: #333;
}

.btn-white:hover,
.btn-white:focus {
  background-color: #474D4F;
  color: #fff;
}

.btn-plain {
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
}

.btn-plain:hover,
.btn-plain:focus {
  background-color: #474D4F;
  color: #ddd;
}

.btn-plain-red {
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
}

.btn-plain-red:hover,
.btn-plain-red:focus {
  background-color: #C6292E;
  color: #ddd;
}

.icon-map-marker {
  background: url("/img/icons/map.png") 0 -24px;
}

.icon-map-marker-hov {
  background: url("/img/icons/map.png") 0 0;
}

.icon-map-marker-hov:hover,
.icon-map-marker.active,
.icon-map-marker-hov.active {
  background: url("/img/icons/map.png") 0 -48px;
}

.icon-search {
  background: url("/img/icons/search.png") 0 -24px;
}

.icon-search-hov {
  background: url("/img/icons/search.png") 0 0;
}

.icon-search-hov:hover,
.icon-search.active,
.icon-search-hov.active {
  background: url("/img/icons/search.png") 0 -48px;
}

.icon-user {
  background: url("/img/icons/user.png") 0 -24px;
}

.icon-user-hov {
  background: url("/img/icons/user.png") 0 0;
}

.icon-user-hov:hover,
.icon-user.active,
.icon-user-hov.active {
  background: url("/img/icons/user.png") 0 -48px;
}

.icon-order {
  background: url("/img/icons/order.png") 0 -24px;
}

.icon-order-hov {
  background: url("/img/icons/order.png") 0 0;
}

.icon-order-hov:hover,
.icon-order.active,
.icon-order-hov.active {
  background: url("/img/icons/order.png") 0 -48px;
}

.icon-bag {
  background: url("/img/icons/bag.png") 0 -24px;
}

.icon-bag-hov {
  background: url("/img/icons/bag.png") 0 0;
}

.icon-bag-hov:hover,
.icon-bag.active,
.icon-bag-hov.active {
  background: url("/img/icons/bag.png") 0 -48px;
}

.icon-close {
  width: 15px;
  height: 15px;
  background: url("/img/icons/close1.png") 0 -13px;
  background-size: 15px 30px;
  display: inline-block;
}

.icon-map-marker,
.icon-map-marker-hov:hover,
.icon-search,
.icon-search-hov:hover,
.icon-user,
.icon-user-hov:hover,
.icon-order,
.icon-order-hov:hover,
.icon-bag,
.icon-bag-hov:hover,
.icon-map-marker-hov,
.icon-search-hov,
.icon-user-hov,
.icon-order-hov,
.icon-bag-hov,
.icon-map-marker.active,
.icon-search.active,
.icon-user.active,
.icon-order.active,
.icon-bag.active,
.icon-map-marker-hov.active,
.icon-search-hov.active,
.icon-user-hov.active,
.icon-order-hov.active,
.icon-bag-hov.active {
  width: 24px;
  height: 24px;
  background-size: 24px 72px;
  display: inline-block;
}

@media (max-width: 767px) {

  .icon-search,
  .icon-search-hov:hover,
  .icon-user,
  .icon-user-hov:hover,
  .icon-order,
  .icon-order-hov:hover,
  .icon-bag,
  .icon-bag-hov:hover,
  .icon-search-hov,
  .icon-user-hov,
  .icon-order-hov,
  .icon-bag-hov,
  .icon-search.active,
  .icon-user.active,
  .icon-order.active,
  .icon-bag.active,
  .icon-search-hov.active,
  .icon-user-hov.active,
  .icon-order-hov.active,
  .icon-bag-hov.active {
    margin-top: 20px;
  }

  .navbar-nav-right>li>a {
    color: #9d9d9d;
    padding: 0 0;
  }

  .navbar-brand {
    padding-left: 0;
  }
  .custom-navbar-brand {
    padding-left: 0;
  }
}

#video-wrapper {
  background-color: #CCCCCC;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  padding: 10px 20px 35px;
}

@media (max-width: 768px) {
  #video-wrapper {
    overflow: hidden;
    padding: 10px 20px 35px;
    height: auto;
  }
}

.section-info {
  text-align: center;
}

#information {
  padding: 30px 0 20px;
}

a {
  color: #333;
}

#information>.row {
  margin-right: 0;
  margin-left: 0;
}

#information .section-banner>img {
  display: block;
  width: 100%;
  height: auto;
  margin: 30px 0;
}

#social {
  padding-bottom: 70px;
}

#social h3 {
  text-align: center;
  margin-bottom: 50px;
}

#social h3:after {
  position: absolute;
  content: '';
  display: block;
  height: 2px;
  width: 48px;
  margin-left: -20px;
  left: 50%;
  margin-top: 30px;
  background: #333;
}

.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
}

.modal {
  padding-top: 8%;
  outline: none;
}

.modal-content {
  border-radius: 0;
  width: inherit;
  height: inherit;
  margin: 0 auto;
}

.modal-logo {
  padding: 20px 0 0;
  text-align: center;
  clear: both;
}

.modal-logo img {
  width: 100px;
}

.modal-body {
  text-align: center;
  padding-bottom: 40px;
}

.close-modal {
  display: block;
  width: 24px;
  height: 24px;
  background: url("/img/icons/close.png");
  background-size: 24px 24px;
  float: right;
  margin-top: -10px;
  margin-right: -10px;
  cursor: pointer;
  position: relative;
  z-index: 9;
}

.navigation {
  z-index: 1041;
  top: 69px;
  position: fixed;
  background-color: #fff;
  display: none;
  right: 0;
}

.navigation.collapsed {
  display: block;
}

.navigation.has-nav-banner {
  top: 114px !important;
}

.navigation>.show {
  display: block;
}

.navigation>#nav-search {
  display: none;
  height: 60px;
}

.navigation>#nav-category {
  display: none;
  background-color: #000000;

  .category-container {
    padding: 30px 0;

    div[class^="col"] {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  a {
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;

    &:hover {
      text-decoration: underline;
      color: #C6292E;
    }
  }
}

.navigation>#nav-order,
.navigation>#nav-register {
  display: none;
  padding: 20px 20px;
}

.navigation>#nav-bag {
  display: none;
  min-height: 200px;
  width: 400px;
  padding: 20px;
  text-align: center;
}

.navigation>#nav-bag hr {
  border-top: 2px solid #eee;
}

.navigation>#nav-account {
  display: none;
  min-height: 200px;
  width: 300px;
  padding: 20px;
  text-align: left;
}

.navigation>#nav-account hr {
  border-top: 2px solid #eee;
}

#nav-account img {
  width: 60px;
}

#nav-account ul {
  list-style: none;
  padding-left: 0;
}

#nav-account ul li {
  padding: 7px 0;
}

#nav-account ul .logout {
  margin-top: 15px;
  border-top: 2px solid #eee;
  padding-top: 15px;
}

.navigation>#nav-search input {
  width: 100%;
  text-align: center;
  height: 60px;
  font-size: 20px;
  border: none;
}

.navigation>#nav-search input:focus {
  outline-width: 0;
}

.navigation>#nav-order input:not([type='submit']) {
  width: 100%;
  padding: 2px 5px;
}

.reg-v-line {
  float: left;
  border-left: 2px solid #ddd;
  height: 250px;
  top: 60px;
  position: relative;
}

.reg-h-line {
  border-top: 2px solid #ddd;
  position: relative;
  float: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.col-xs-6.col-reg {
  width: 49%;
}

@media (min-width: 992px) {
  .col-md-6.col-reg {
    width: 49%;
  }
}

.checkbox input[type=checkbox] {
  position: relative;
  margin-top: 4px;
  margin-left: 0;
}

.site-header-less {
  padding: 30px 0;
  text-align: center;
}

.site-header-less img {
  width: 200px;
}

@media (min-width: 768px) {
  #checkout .col-sm-6 {
    float: right;
  }
}

@media (max-width: 767px) {
  .checkout-step {
    margin-top: 50px;
  }

  .navigation>#nav-category {
    .category-container {
      padding: 0;
      max-height: 340px;
      overflow: auto;
      div[class^="col-"] {
        padding: 10px 20px;
        border-top: 1px solid #ddd;
        a{
          text-align: center;
          display: block;
          color: #9d9d9d;
          line-height: 48px;
          font-size: 13px;
          text-decoration: none;
          letter-spacing: 0.2em;
        }
      }
    }
  }

  //#checkout .btn {
  //  font-size: 10px !important;
  //}
}

#checkout {
  padding-bottom: 50px;
}

#checkout a,
#checkout button {
  font-size: 13px;
}

#checkout h3 {
  margin-bottom: 30px;
}

.checkout-step {
  background-color: #dddddd;
  padding: 25px;
}

.checkout-step h4,
.checkout-step h5 {
  font-weight: bold;
}

.checkout-step p.info {
  font-size: 12px;
}

.hr-line {
  width: 46%;
  margin-top: 10px;
  border-top: 2px solid #9d9d9d;
  float: left;
}

.or {
  text-align: center;
  width: 8%;
  float: left;
}

.hr-line2 {
  width: 40%;
  margin-top: 10px;
  border-top: 2px solid #9d9d9d;
  float: left;
}

.hr-text {
  text-align: center;
  width: 20%;
  float: left;
}

.a-red {
  color: #C6292E;
  text-decoration: underline;
}

.a-red:focus,
.a-red:hover {
  color: #474D4F;
  text-decoration: none;
}

.a-dark {
  color: #474D4F;
  text-decoration: underline;
}

.a-dark:focus,
.a-dark:hover {
  color: #C6292E;
  text-decoration: none;
}

.a-purple {
  color: #662884;
  text-decoration: none;
}

.a-purple:focus,
.a-purple:hover {
  color: #474D4F;
  text-decoration: underline;
}

#content .step {
  margin-top: 20px;
}

ul.step {
  list-style: none;
  padding-left: 0;
}

#content .step>li {
  border-top: 2px solid #B1B1B1;
  position: relative;
  display: block;
  padding: 15px 0;
  font-weight: bold;
  font-size: 17px;
  color: #B1B1B1;
}

.checkout-detail .section {
  padding-top: 10px;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.checkout-detail .product-detail {
  margin-bottom: 20px;
}

.checkout-detail .product-detail .product-img img {
  width: 120px;
}

.checkout-detail .product-detail .product-img {
  text-align: center;
}

.checkout-detail .product-detail .stock {
  margin-top: 40px;
}

.checkout-detail .product-detail .product-info hr {
  margin-bottom: 5px;
  border-top: 2px solid #ddd;
}

.price-detail {
  text-align: right;
}

.price-detail p {
  font-size: 13px;
}

.red {
  color: #C6292E;
}

#checkout .panel {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  margin-top: -2px;
}

#checkout .panel-default>.panel-heading {
  background-color: transparent;
}

#checkout .panel-heading {
  padding: 0 0;
}

#checkout .panel-body {
  border-top: 0;
  padding: 0;
}

#checkout .panel-title a {
  display: block;
  padding: 13px 10px 13px 0;
  text-decoration: none;
}

.cont-shop {
  float: right;
  margin-top: 27px;
}

#btn-register-form {
  margin-top: 15px;
}

.reseller-registration {
  border: 2px solid #ddd;
  padding: 0 30px;
}

@media (max-width: 768px) {
  .reseller-registration {
    border: 0;
    padding: 0;
  }
}

.reseller-registration h3 {
  margin-bottom: 30px;
}

.reseller-info {
  border-right: 2px solid #ddd;
}

.reseller-info .img-profile {
  margin-bottom: 20px;
}

.reseller-info .img-profile img {
  width: 80px;
}

.reseller-info-link .section {
  margin-top: 20px;
}

.reseller-info-link .section h4 {
  font-weight: bold;
}

.reseller-info-link .section ul {
  list-style: none;
  padding-left: 0;
}

.reseller-products-header,
.reseller-personal-header {
  padding-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.reseller-products-items {
  text-align: center;
  margin: 15px 0;
}

.reseller-products-items .item {
  padding: 15px 0;
}

.reseller-products-items img {
  width: 100px;
}

.reseller-products-items select,
.reseller-products-items .qty,
.reseller-products-items .item-btn {
  margin-top: 50px;
}

.reseller-points-header {
  padding: 25px;
}

.reseller-points-header>p {
  float: right;
  margin-right: 140px;
}

.reseller-points-balance {
  border: 5px solid #333333;
}

.reseller-points-balance .col-6 {
  float: left;
  width: 50%;
  padding: 20px;
  text-align: center;
}

.reseller-points-balance .col-6.line {
  border-left: 2px solid #ddd;
  text-align: left;
}

.reseller-points-balance .col-6.line h3 {
  margin-top: 0;
}

.reseller-points-history {
  border-top: 2px solid #ddd;
}

.reseller-points-history table {
  width: 100%;
  text-align: center;
}

.reseller-points-history table tr {
  border-bottom: 1px solid #ddd;
}

.reseller-points-history table thead {
  border-top: 1px solid #ddd;
}

.reseller-points-reward {
  text-align: center;
}

.reseller-points-reward h2 {
  margin-top: 50px;
}

.reseller-personal-body {
  padding-bottom: 50px;
  border-bottom: 2px solid #ddd;
}

.reseller-personal-body form {
  margin-top: 20px;
}

.reseller-personal-banner {
  margin-top: 30px;
}

.reseller-personal-banner .banner {
  background-color: #2e3436;
  color: #fff;
  padding: 30px 25px 25px;
}

.reseller-personal-banner .banner .point {
  font-size: 70px;
  margin: 0;
  text-align: center;
}

.reseller-personal-banner .banner .title {
  margin-top: 5px;
}

.reseller-personal-banner .banner p {
  margin: 0;
  text-align: center;
}

.order-status-header,
.contact-us-header {
  border-bottom: 2px solid #ddd;
}

.order-status-body,
.contact-us-body,
.faq-body,
.search-body {
  padding: 40px 0 50px;
}

.tutorial-body {
  padding: 40px 0 10px;
}

.order-status-body table {
  text-align: center;
  width: 100%;
}

.order-status-body table thead {
  border-top: 1px solid #ddd;
}

.order-status-body table tr {
  border-bottom: 1px solid #ddd;
}

.order-status-body th,
.order-status-body td {
  padding: 10px 0;
  text-align: center;
}

.contact-us-banner .item {
  background-color: #ddd;
  padding: 20px;
  min-height: 150px;
}

.contact-us-banner .item h4 {
  font-weight: bold;
}

.faq-header,
.search-header,
.tutorial-header {
  text-align: center;
}

#faq .panel {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  margin-top: -2px;
}

#faq .panel-default>.panel-heading {
  background-color: transparent;
}

#faq .panel-heading {
  padding: 0 0;
}

#faq .panel-body {
  border-top: 0;
  padding: 15px 10px;
}

#faq .panel-title a {
  display: block;
  padding: 15px 10px;
  text-decoration: none;
}

.search-body .items,
.tutorial-body .items {
  text-align: center;
  margin-bottom: 50px;
}

.search-body .items img {
  width: 200px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.tutorial-body .items img {
  width: 200px;
  margin-bottom: 10px;
}

.search-body .items p,
.tutorial-body .items p {
  margin-top: 5px;
}

#page-wrapper .section-banner {
  margin-top: -30px;
}

.membership-header {
  text-align: center;
  margin: 50px 0;
}

.mem-col-4,
.mem-col-4-center {
  width: 33.33333333%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 50px;
}

.mem-col-4-center {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.membership-body h3 {
  margin-bottom: 50px;
}

.b-t-1 {
  border-top: 1px solid #ddd;
}

.story img {
  margin-top: 20px;
}

.story hr {
  margin: 10px 0;
}

.story .story-img {
  text-align: center;
}

.story .story-img img {
  display: initial;
}

.review-body .product img {
  width: 100px;
  float: left;
}

.review-header {
  margin-bottom: 30px;
}

.content-header {
  text-align: left;
  margin-bottom: 20px;
}

.content-header.filter {
  text-align: right;
}

.content-header .btn-group {
  margin-top: 20px;
  margin-bottom: 10px;
}

.media .content-body .product,
.media .content-body .suggest {
  margin-top: 50px;
  text-align: center;
}

.media .content-body .suggest h3 {
  margin-bottom: 45px;
}

.media .content-body .suggest img {
  width: 100%;
}

.media .content-body .product .img-product img {
  width: 150px;
}

.media .content-body .product .img-product a {
  position: absolute;
  left: 50%;
  margin-left: -59px;
  margin-top: 100px;
}

.products .items {
  text-align: center;
}

.products .items img {
  width: 200px;
  margin-bottom: 15px;
}

.products .items span {
  display: block;
}

.products .items .price {
  margin: 10px 0;
}

.products .items .btn-plain-red {
  padding: 8px 40px;
}

.products .items {
  a.product-name {
    color: #333;
  }

  a.product-name:hover {
    color: #C6292E;
    text-decoration: underline;
  }
}

#map {
  height: 400px;
  width: 100%;
}

@media(min-width: 768px) {
  .maps .map-body {
    height: 400px;
    border: 1px solid #ddd;
  }

  .maps .map-body .stores {
    height: 400px;
    overflow: scroll;
    overflow-x: hidden;
  }
}

@media(max-width: 768px) {
  .maps .map-body .stores {
    min-height: inherit;
    overflow: scroll;
  }
}

.maps .map-body .stores .store {
  padding: 5px 0;
}

.maps .search-form {
  display: inline-block;
  width: 70%;
  margin: auto 10.5em;
}

.maps .search-form p,
.maps .search-form span,
.maps .search-form select {
  float: left;
  margin: 0 10px
}

.maps .search-form select {
  width: 30%;
}

.detail .panel {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 2px solid #c62968;
  border-bottom: 2px solid #c62968;
  margin-top: -2px !important;
}

.detail .panel-default>.panel-heading {
  background-color: transparent;
}

.detail .panel-heading {
  padding: 0 0;
}

.detail .panel-body {
  border-top: 0;
  padding: 15px 10px;
}

.detail .panel-title a {
  display: block;
  padding: 15px 10px;
  text-decoration: none;
  color: #c62968;
}

.detail .list-detail-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.detail .list-detail-body .notify {
  width: 100%;
  height: 70px;
  margin-bottom: 30px;
  padding-top: 18px;
  font-size: 23px;
}

.detail .hands-on {
  text-align: center;
}

.detail .hands-on img {
  width: 150px;
}

.detail .reviews .reviews-header h3 {
  //text-align: center;
  margin-bottom: 50px;
}

.detail .reviews .reviews-header {
  margin-bottom: 50px;
}

.detail .reviews .reviews-body .review-detail {
  padding-top: 7px;
}

.detail .reviews .reviews-body .review-content img {
  width: 200px;
  margin-bottom: 30px;
}

.detail .reviews .reviews-body .review-content {
  margin-top: 10px;
}

.detail .reviews .reviews-body .review-content .desc {
  margin-bottom: 30px;
}

.detail .reviews .reviews-body .review-content .red {
  margin-bottom: 30px;
}

.detail .reviews .reviews-body .review-content .rate p {
  color: #999;
}

.detail .reviews .reviews-body .review-content .rate button {
  margin-right: 10px;
  float: left;
}

.detail .reviews .reviews-body .review-content .rate div {
  margin-right: 10px;
  padding-top: 1px;
  float: left;
}

.detail .reviews .reviews-body .review-content h3 {
  margin-top: 10px;
}

.jq-ry-container {
  padding: 0 0 !important;
}

.f-l {
  float: left;
}

#num-rating {
  margin-left: 10px;
}

.slider .slick-slide {
  text-align: center;
  padding: 10px;
}

@media (max-width: 700px) {
  .slider .slick-slide {
    // margin-left: 1%;
  }
  .slider .slick-slide img {
    margin: 0 auto;
  }
}

// .slider .slick-slide img {
//width: 150px;
//box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
//-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
//-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
//-o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
// }

.slick-prev:before,
.slick-next:before {
  color: #333 !important;
}

.detail #social {
  padding-top: 50px;
  margin: 50px 0;
  background-color: #f5f5f5;
}

.detail #social .row-social {
  margin-left: 30px;
  margin-right: 30px;
}

/*#gallery_01 img{border:2px solid white;}*/

/*!*Change the colour*!*/
/*.active img{border:2px solid #333 !important;}*/

#social .ig-item {
  padding: 25px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  margin: 25px 0;
}

#social .ig-item img {
  width: 100%;
}

.product-trigger {
  background-color: #fff;
  width: 160px;
  text-align: center;
  padding: 10px;
  position: absolute;
  margin-top: -39px;
  cursor: pointer;
}

.product-trigger i {
  margin-top: 1px;
  margin-right: 5px;
}

.carousel-item-backdrop {
  background-color: #000000;
  position: absolute;
  width: 101%;
  opacity: .5;
  height: 100%;
  top: 0;
  left: 0;
}

.carousel-item {
  height: 253px;
  min-height: 200px;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.carousel-item img {
  width: 50%;
}

.carousel-item p {
  margin-bottom: 5px;
}

.carousel-item:hover {
  cursor: pointer;
}

.carousel-product-mobile {
  text-align: center;
  padding: 15px 0;
}

.carousel-product-mobile img {
  width: 50%;
}

.modal-subscription .modal-body {
  padding: 0;
  background-color: #000000;
}

@media (max-width: 768px) {

  .modal-subscription .modal-body .row {
    padding: 0 30px 40px;
  }

  .mem-col-4,
  .mem-col-4-center {
    width: 100%;
    float: none;
  }

  .reseller-points-balance .col-6 {
    float: none;
    width: 100%;
    padding: 15px;
  }

  .reseller-points-balance .col-6.line {
    text-align: center;
    border: 0;
    border-top: 2px solid #ddd;
  }

  .reseller-points-header>p {
    float: none;
    text-align: center;
    margin-right: 0;
    font-size: 20px;
    margin-top: 10px;
  }

  .reseller-points-history .history-table {
    overflow-x: auto;
  }

  .reseller-points-history table tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .reseller-points-history table td {
    padding: 5px 10px;
  }

  .reseller-points-history table td div {
    width: 100px;
  }

  .reseller-personal-header {
    margin-top: 20px;
    padding-bottom: 0;
    border: 0;
    border-bottom: 2px solid #ddd;
  }

  .reseller-personal-banner .banner {
    margin-bottom: 10px;
  }

  .reseller-personal-body {
    border-bottom: 0;
  }

  .contact-us-banner .item {
    margin: 25px 0;
  }
}

.modal-subscription .col-sm-5 {
  padding-right: 0;
}

.modal-subscription .newsletter {
  color: #fff;
  padding-left: 0;
}

.modal-subscription .newsletter a {
  color: #fff;
  text-decoration: underline;
}

.modal-subscription .newsletter a:hover {
  text-decoration: none;
}

.modal-subscription .newsletter input {
  width: 80%;
  background-color: transparent;
  text-align: center;
  margin: 10px auto;
}

.modal-subscription .newsletter input:focus {
  border-color: #fff;
  box-shadow: none;
  outline: 0 none;
}

.modal-subscription .newsletter button {
  background-color: #000;
  border: 1px solid #fff;
  padding: 2px 30px;
  margin-bottom: 20px;
}

.modal-subscription .newsletter button:hover {
  background-color: #C6292E;
  color: #000;
  border: 1px solid #C6292E;
}

.modal-feed .modal-body {
  padding: 0;
  text-align: left;
}

.modal-feed img {
  width: 100%;
}

.modal-feed .col-md-5 {
  padding-left: 0;
  padding-right: 30px;
}

.modal-feed .logo {
  width: 60px;
  border: 1px solid #ddd;
  padding: 20px 10px;
}

.modal-feed .logo-ig {
  height: 60px;
  padding: 22px 12px;
}

.modal-feed .product {
  margin-top: 20px;
  text-align: center;
  padding: 0 50px;
}

.modal-feed .product img {
  width: 50%;
}

.modal-feed .product h4 {
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 991px) {
  .modal-feed img {
    margin-top: -14px;
  }

  .modal-feed .col-md-5 {
    padding-left: 30px;
    margin-top: 40px;
  }

  .modal-feed .modal-body {
    padding-bottom: 50px;
  }
}

#modal-referral .modal-body {
  padding: 0;
  height: 424px;
  background-size: 701px auto;
  background-image: url("/img/samples/modal-referral2.jpg");
  background-repeat: no-repeat;
}

@media(max-width: 768px) {
  #modal-referral .modal-body {
    padding: inherit !important;
    height: inherit !important;
    background: none !important;
    padding-bottom: 15px !important;
  }

  #modal-referral .modal-dialog {
    width: inherit !important;
  }

  #modal-referral .modal-body .referral {
    padding: 35px !important;
  }

  #modal-referral .modal-body .referral .email input {
    width: 70% !important;
  }
}

#modal-referral .modal-dialog {
  width: 700px;
}

#modal-referral .modal-body .referral {
  padding: 50px 36px;
  text-align: left;
}

#modal-referral .modal-body .referral .link {
  margin: 30px 0;
}

#modal-referral .modal-body .referral .link .post {
  margin-top: 10px;
}

#modal-referral .modal-body .referral .link input {
  width: 70%;
}

#modal-referral .modal-body .referral .link button {
  margin-left: -5px;
}

#modal-referral .modal-body .referral .email {
  margin-bottom: 20px;
}

#modal-referral .modal-body .referral .email p {
  font-weight: bold;
  margin-bottom: 0;
}

#modal-referral .modal-body .referral .email input {
  width: 50%;
}

#modal-referral .modal-body .referral .email button {
  margin-left: -5px;
}

.reseller-addresses-header {
  padding: 10px;
  border-bottom: 2px solid #ddd;
  margin-bottom: 30px;
}

.reseller-addresses-header button {
  margin-top: 20px;
}

.reseller-addresses-body .address {
  margin-bottom: 30px;
}

.reseller-addresses-body .address-body {
  padding-left: 20px;
  margin-bottom: 20px;
}

#modal-new-address .modal-body {
  padding: 0;
  min-height: 300px;
  text-align: left;
}

//#modal-new-address .modal-dialog {
//  width: 450px;
//}

#modal-new-address .modal-body .new-address {
  padding: 20px 40px;
}

#modal-new-address .modal-body .new-address .address-save {
  margin: 30px 0;
  text-align: center;
}

#modal-new-address .modal-body .new-address .address-save input {
  width: 150px;
}

#modal-checkout .option .btn,
#modal-checkout-leave .option .btn {
  width: 49%;
}

#modal-checkout .option,
#modal-checkout-leave .option {
  margin: 50px 0 15px;
}

@media (min-width: 768px) {

  #modal-checkout .modal-dialog,
  #modal-checkout-leave .modal-dialog {
    width: 570px;
  }
}

#modal-checkout .modal-body,
#modal-checkout-leave .modal-body {
  padding: 20px 40px;
}

#modal-register .modal-body {
  padding: 0;
}

#modal-register .modal-body .register {
  padding: 15px 30px 30px;
}

.products-banner {
  margin-bottom: 30px;
}

.products-banner img {
  margin-top: -30px;
  width: 100%;
}

.products .items .quick-shop {
  position: absolute;
  top: 80px;
  margin: 0 auto;
  left: 32%;
}

@media (max-width: 768px) {
  .products .items .quick-shop {
    display: none;
  }
}

.products .items-row {
  margin-top: 20px;
}

.modal-no-stock .modal-body {
  padding: 0;
  text-align: left;
}

.modal-no-stock .modal-body .stock {
  padding: 30px 40px 25px;
}

.modal-no-stock .modal-body .stock img {
  width: 100px;
  margin-right: 15px;
}

.modal-no-stock .modal-body .stock input {
  width: 79%;
}

.modal-no-stock .modal-body .stock button {
  margin-left: -5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.product-select,
.tutorial-select,
.blog-select,
.story-select,
.beauty-select {
  background-image: url("/img/icons/select.png");
  background-repeat: no-repeat;
}

.product-select,
.tutorial-select {
  background-size: 109px;
  background-position: left 19px;
}

.beauty-select {
  background-size: 120px;
  background-position: left 19px;
}

.blog-select,
.story-select {
  background-size: 73px;
  background-position: left 22px;
}

.product-select a,
.tutorial-select a,
.blog-select a,
.story-select a, 
.beauty-select a{
  color: #fff !important;
}

@media(max-width: 768px) {

  .product-select a,
  .tutorial-select a,
  .blog-select a,
  .story-select a,
  .beauty-select a {
    color: #333 !important;
  }
}

.error-404 {
  padding: 70px 0;
}

.error-404 img {
  width: 150px;
}

.error-404 .error-body {
  padding-top: 50px;
  padding-left: 40px;
}

.modal-quick-shop .product {
  text-align: center;
}

.modal-quick-shop .product img {
  width: 70%;
}

.modal-quick-shop .modal-body {
  text-align: left;
  padding-top: 40px;
}

.modal-quick-shop .modal-body .new {
  background-color: #C6292E;
  color: #fff;
  padding: 5px 20px;
  width: 72px;
}

.modal-quick-shop .modal-body .btn {
  width: 80%;
  margin-top: 50px;
}

.modal-quick-shop .modal-body .colors {
  margin-bottom: 20px;
}

.modal-quick-shop .modal-body .colors img {
  width: 30px;
  cursor: pointer;
}

.modal-quick-shop .gallery {
  text-align: left;
  padding: 0 10px;
}

.modal-quick-shop .gallery img {
  width: 50px;
  cursor: pointer;
}

.modal-quick-shop .product-img {
  display: none;
}

.modal-quick-shop .product-img.active {
  display: block;
}

.gold {
  color: rgba(214, 169, 13, 0.73);
}

.h-dark {
  border-top: 1px solid #bfbfbf !important;
}

.col-n-p {
  padding-left: 0;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-0 {
  margin-top: 0;
}

.f-s-12 {
  font-size: 12px;
}

#modal-register .modal-dialog {
  width: 450px;
}

.tooltip {
  width: 170px;
}

.tooltip>.tooltip-inner {
  opacity: 1;
  background: #ffc9c1;
  color: #000;
  filter: alpha(opacity=100);
  padding: 10px;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #ffc9c1;
}

.tooltip.in {
  opacity: 1 !important;
  filter: alpha(opacity=100);
}

.input-group-btn {
  vertical-align: top;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.f-s-14 {
  font-size: 14px !important;
}

.reseller-checkout {
  padding-top: 114px;
}

.active-red a {
  color: #C6292E;
}

.referral-banner img {
  width: 100%;
}

.referral-banner .referral-body {
  background-color: #C6292E;
  height: 100%;
  text-align: center;
  padding: 55px;
}

.referral-banner .referral-body p {
  color: #fff;
}

.referral-banner .referral-body button {
  margin-top: 24px;
}

.col-n-r-p {
  padding-right: 0;
}

.col-n-l-p {
  padding-left: 0;
}

.account-review-body {
  margin-top: 30px;
}

.account-review-body .img-product {
  text-align: center;
}

.account-review-body .img-product img {
  width: 100%;
}

.account-review-body .img-related {
  width: 50px;
}

.account-review-body .img-review {
  width: 100%;
}

.grey {
  color: #9d9d9d;
}

//.account-info {
//  border-right: 2px solid #ddd;
//}

@media (min-width: 768px) {
  .account-content {
    border-left: 2px solid #ddd;
    padding-left: 60px !important;
    min-height: 500px;
  }
}

.account-info .img-profile {
  margin-bottom: 20px;
}

.account-info .img-profile img {
  width: 80px;
}

.account-info-link .section {
  margin-top: 20px;
}

.account-info-link .section a {
  font-size: 12px;
}

.account-info-link .section h4 {
  font-weight: bold;
}

.account-info-link .section ul {
  list-style: none;
  padding-left: 0;
}

.status-pending {
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  width: 90px;
  background-color: #ffda10;
  color: #fff;
}

.status-confirm {
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  background-color: #5fd2cf;
  color: #fff;
}

.status-shipped {
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  width: 90px;
  background-color: #245ed2;
  color: #fff;
}

.status-delivered {
  text-align: center;
  margin: 0 auto;
  padding: 5px;
  width: 90px;
  background-color: #37d251;
  color: #fff;
}

.account-points-header {
  padding: 25px;
}

.account-points-header>p {
  float: right;
  margin-right: 140px;
}

.account-points-balance {
  border: 5px solid #333333;
}

.account-points-balance .col-6 {
  float: left;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.account-points-balance .col-6 h3 {
  margin-top: 12px;
}

.account-points-balance .col-6.line {
  border-left: 2px solid #ddd;
}

.account-points-history {
  border-top: 2px solid #ddd;
}

.account-points-history table {
  width: 100%;
  text-align: center;
}

.account-points-history table tr {
  border-bottom: 1px solid #ddd;
}

.account-points-history table thead {
  border-top: 1px solid #ddd;
}

.account-points-reward {
  text-align: center;
}

.account-points-reward h2 {
  margin-top: 50px;
}

.progress-bar-grey {
  background-color: #ddd;
}

.progress {
  border-radius: 0;
  margin-left: 20px;
  margin-right: 100px;
}

.progress-max {
  position: absolute;
  top: 0;
  right: 0;
}

.account-order-body table.detail,
.account-order-body table.detail th {
  text-align: center;
}

.account-order-body table.detail th {
  background-color: #ddd;
  border: 1px solid #9d9d9d !important;
}

.account-order-body table.detail td {
  vertical-align: middle !important;
  display: table-cell;
}

.account-order-body .img-product {
  width: 100px;
}

.b-t-0 {
  border-top: 0 !important;
}

.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}

.shipping-table {
  text-align: left !important;
}

.shipping-table td {
  padding: 10px 5px;
}

.yellow {
  color: #ffda10;
}

#rate-label {
  margin-left: 10px;
  padding-top: 2px;
}

.social {
  margin-top: 10px;
  border-radius: 50%;
  border: 1px solid #9d9d9d;
}

.social.social-facebook {
  padding: 5px 11px;
}

.social.social-pinterest {
  padding: 5px 9px;
}

.social.social-twitter {
  padding: 5px 8px;
}

.media .product .product-header {
  width: 300px;
  margin: 0 auto 15px;
  border-bottom: 1px solid #ddd;
}

.quote {
  width: 80%;
  margin: 25px auto 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 100;
}

.search {
  position: relative;
}

.search input {
  text-indent: 30px;
}

.search .fa-search {
  position: absolute;
  top: 9px;
  right: 10px;
  font-size: 15px;
  color: #9d9d9d;
}

.variant-header {
  font-weight: bold;
  margin-bottom: 0;
}

.hands-on a:hover {
  color: #C6292E;
  text-decoration: underline;
}

.variant-collection {
  color: #555;
  margin-bottom: 0;
}

.variant-color {
  margin-bottom: 0;
}

.purple {
  color: #d61ea4;
  font-weight: bold;
  font-size: 10px;
  margin-top: 5px;
}

#product-rating {
  margin-top: 2px;
  margin-left: 20px;
}

.modal-quick-shop {
  .rating {
    margin-bottom: 10px;
  }

  #product-rating {
    margin-left: 0;
  }

  .more-detail {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .modal-quick-shop .modal-dialog {
    width: 650px;
  }
}

hr#detail {
  //margin-left: -100px;
  //margin-right: -100px;
  border-top: 2px solid #cf3636;
}

.detail .new {
  position: absolute;
  top: 0;
  right: 15px;
  background: #cf3636;
  color: #fff;
  padding: 3px 20px;
}

.payment-confirmation {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 120px;
}

.payment-confirmation .header {
  margin-bottom: 50px;
}

.payment-confirmation .info {
  margin-top: 40px;
  margin-bottom: 20px;
}

#nav-bag .btn {
  width: 100%;
}

.slider img {
  cursor: pointer;
}

.carousel-item .active {
  display: none;
}

.m-b-0 {
  margin-bottom: 0;
}

.f-s-16 {
  font-size: 16px;
}

.tutorial-slider button {
  bottom: -25px;
  top: auto;
}

.tutorial-slider .slick-prev {
  left: 0;
}

.tutorial-slider .slick-next {
  right: 0;
}

#tutorial-index {
  text-align: center;
  margin-top: -25px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 2px;
}

#detail-zoom {
  // margin-left: 50px;
  text-align: center;
}

@media (min-width: 768px) {
  #detail-zoom .zoomWrapper {
    // margin-left: 70px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  #detail-zoom #product-gallery {
    // margin-left: 20px;
  }
  #detail-zoom img {
    position: relative!important;
  }
}

.zoomContainer,
.zoomWrapper,
.zoomWrapper #product-zoom {
  max-width: 400px !important;
  max-height: 400px !important;
}

.gallery-item {
  width: 90px;
  margin: 0 3px;
  border: 2px solid #ddd;
}

#detail-float {
  width: 100%;
  background-color: #171717;
  position: fixed;
  top: 114px;
  left: 0;
  z-index: 1040;
  padding: 5px 0;
}

#detail-float img {
  width: 70px;
  float: left;
}

#detail-float p {
  color: #ddd;
}

#detail-float h4 {
  margin-bottom: 0;
  color: #fff;
}

#detail-float .title {
  float: left;
  margin-left: 10px;
}

#detail-float .buy {
  margin-top: 10px;
  margin-bottom: 10px;
}

#detail-float .buy select {
  width: 70px;
  padding: 6px;
}

#detail-float .buy .btn {
  width: 200px;
  margin-left: 10px;
  margin-top: -2px;
}

.bags {
  text-align: left;
  overflow-y: scroll;
  max-height: 200px;
}

.bags .bag {
  width: 100%;
  margin-bottom: 30px;
}

.bags .bag .product img {
  width: 80px;
}

.bags .bag .product {
  width: 25%;
  float: left;
}

.bags .bag .desc {
  width: 75%;
  float: left;
}

.bags .bag .desc select {
  width: 50px;
}

.bags .bag .desc .title {
  min-height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.bags .bag .desc .title h4,
.bags .bag .desc .title p {
  margin-top: 0;
  width: 50%;
  float: left;
}

.bags .bag .desc .a-red {
  text-decoration: none;
}

#nav-bag .total {
  text-align: left;
  border-top: 1px solid #333;
  padding-top: 15px;
}

#nav-bag .total {
  text-align: left;
  border-top: 1px solid #333;
  padding-top: 15px;
}

#nav-bag .btn-checkout {
  width: 50%;
  margin-bottom: 20px;
}

.rating-left {
  justify-content: flex-start;
}

.rating-right {
  justify-content: flex-end;
}

.carousel-control.left,
.carousel-control.right {
  background-image: none;
}

.inverse-link {
  text-decoration: underline !important;
}

.inverse-link:hover {
  text-decoration: none !important;
}

.footer-menu>.col-sm-2>a,
.navbar a {
  letter-spacing: 0.2em;
}

.m-r-10 {
  margin-right: 10px;
}

.blog-img {
  position: relative;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .blog-img {
    margin-bottom: 0;
  }
}

.blog-content {
  background-color: #fff;
  position: absolute;
  width: 300px;
  top: 25%;
  left: -20%;
  padding: 20px;
}

.blog-content-mobile {
  background-color: #fff;
  padding: 20px;
  min-height: 100px;
}

.blog-content h4,
.blog-content-mobile h4 {
  line-height: 150%;
}

.read-more {
  margin-top: 10px;
}

.product-img-wrap {
  height: auto;
  padding: 10px;
}

.product-img {
  position: relative;
}

#balls {
  position: absolute;

  .ball {
    background-color: #000;
    color: #fff;
    float: left;
    margin-right: 5px;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    display: table;
    padding: 8px;
    border: 1px solid #222;

    .message {
      font-size: 100%;
      display: table-cell;
      vertical-align: middle;
      border-radius: 50%;
      text-align: center;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      word-break: break-word;
    }
  }
}

@media(max-width: 768px) {
  #balls {
    left: 15%;

    .ball {
      width: 80px;
      height: 80px;
    }
  }
}

.press-detail {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 40px;
  width: 100%;
  color: #fff;
  padding-top: 10px;
}

p.share {
  height: 13px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-right: 1px;
  float: left;
}

.btn-filter {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  min-width: 140px;
}
.single {
  // padding-left: 15px;
  // margin-top: 40px;
  // background: #fcfcfc;
  // border: 1px solid #f0f0f0; 
}
  .single h3.side-title {
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  font-size: 20px;
  color: #333;
  text-transform: uppercase; }
  .single h3.side-title:after {
  content: '';
  width: 60px;
  height: 1px;
  background: #ff173c;
  display: block;
  margin-top: 6px; }
  
  .single ul {
  margin-bottom: 0; 
}
.single li {
  border-bottom: 1px solid #d8d8d8;
}

  .single .list-unstyled .active a {
    color: #ff173c;
  }
  .single li a {
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f0f0;
  line-height: 30px;
  display: block;
  text-decoration: none; }
  .single li a:hover {
  color: #ff173c; }
  .single li:last-child a {
  border-bottom: 0; }

  .btn .caret {
    margin-left: 5px;
  }

  .search-header h4 {
    display:inline-block;
  }

  .search-header .btn-group {
    float:right;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .search-header {
    text-align:left;
  }
  .search-body {
    padding: 17px 0 30px;
  }

.shop {
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #9b9b9b;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  display: inline-block;

}

.shop:hover {
  transition: 0.5s;
  color: whitesmoke;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #9c1a2a;
}

.item-shop {
  padding: 20px;
  border: solid 1px #979797;
}

.logo-store {
  padding: 15px
}

#product-header {
  background: #333333;
  -webkit-transition: bottom 2s;
  transition: bottom 2s;
  z-index: 10;
}

.navbar-product {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media screen and (max-width: 600px) {
  #product-header {
    display: none;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.custom-navbar-brand {
  float: left;
  height: 50px;
  padding:10px 10px 10px 5px;
  font-size: 18px;
  line-height: 38px;
}

.custom-navbar-brand img {
width: 110px;
}

@media (max-width: 768px) {
.custom-navbar-brand img {
  width: 100px;
}
}
