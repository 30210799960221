.pos-r {
  position: relative !important;
}

.pos-a {
  position: absolute !important;
}

.pos-f {
  position: fixed !important;
}

.w-sm {
  width: 25% !important;
}

.w-md {
  width: 50% !important;
}

.w-lg {
  width: 75% !important;
}

.w-full {
  width: 100% !important;
}

.m-a-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-a {
  margin: 20px !important;
}

.m-t {
  margin-top: 20px !important;
}

.m-r {
  margin-right: 20px !important;
}

.m-b {
  margin-bottom: 20px !important;
}

.m-l {
  margin-left: 20px !important;
}

.m-x {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.m-y {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-a-md {
  margin: 30px !important;
}

.m-t-md {
  margin-top: 30px !important;
}

.m-r-md {
  margin-right: 30px !important;
}

.m-b-md {
  margin-bottom: 30px !important;
}

.m-l-md {
  margin-left: 30px !important;
}

.m-x-md {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.m-y-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-a-lg {
  margin: 60px !important;
}

.m-t-lg {
  margin-top: 60px !important;
}

.m-r-lg {
  margin-right: 60px !important;
}

.m-b-lg {
  margin-bottom: 60px !important;
}

.m-l-lg {
  margin-left: 60px !important;
}

.m-x-lg {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.m-y-lg {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.p-a-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-a {
  padding: 20px !important;
}

.p-t {
  padding-top: 20px !important;
}

.p-r {
  padding-right: 20px !important;
}

.p-b {
  padding-bottom: 20px !important;
}

.p-l {
  padding-left: 20px !important;
}

.p-x {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.p-y {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-a-md {
  padding: 30px !important;
}

.p-t-md {
  padding-top: 30px !important;
}

.p-r-md {
  padding-right: 30px !important;
}

.p-b-md {
  padding-bottom: 30px !important;
}

.p-l-md {
  padding-left: 30px !important;
}

.p-x-md {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.p-y-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-a-lg {
  padding: 60px !important;
}

.p-t-lg {
  padding-top: 60px !important;
}

.p-r-lg {
  padding-right: 60px !important;
}

.p-b-lg {
  padding-bottom: 60px !important;
}

.p-l-lg {
  padding-left: 60px !important;
}

.p-x-lg {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.p-y-lg {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}